.gadgetTray {
  &__list {
    > div {
      height: 100%;
    }

    &__gadgetContainer {
        cursor: grab;
    }
  }
}
