@import 'src/theme/color-palette';

.lessonEditor {
  display: flex;
  
  &__lessonCanvas {
    &__topNotification {
      width: 100%;
      background: rgba(242,240,236,0.9);
      position: sticky;
      top: 0;
      z-index: 3;
    }
    &__gadget {
      &__spinnerContainer {
        margin-bottom: 2rem;
      }
    }
  }
}
