.SectionGadget {
  margin: 0 auto;

  &__heading {
    display: inline-block;
    position: relative;
    padding-bottom: 1rem;

    &::before {
      content: "";
      background-color: #232F3E;
      width: 50%;
      height: 3px;
      position: absolute;
      bottom: 0;
    }
  }
}
