@import 'src/theme/color-palette';
@import 'src/authoring/components/drag-and-drop/styles/dnd-utils';

.DnDListItem {
  position: relative;
  cursor: pointer;

  &--hover {
    &-over,
    &-under {
      @include dnd-list-item-hover-indicator
    }
    &-over {
      @include dnd-list-item-hover-indicator-over
    }

    &-under {
      @include dnd-list-item-hover-indicator-under
    }
  }
}
