.lessonCanvas-container {
  margin: 0 auto;

  .header-gadget-container {
    &:not(:first-child) {
      padding-top: 2rem;
    }
  }
}

.lessonTitle {
  display: flex;
  outline: none;
  font-weight: 600;
  word-wrap: break-word;
  white-space: break-spaces;
  margin: 4rem 0 2rem 0;
}

.lessonTitle__header {
  width: 100%;
  margin: 0 auto;
}

.gadget {
  box-sizing: border-box;
  cursor: default;
  min-height: 1em;
  transition: border 0.28s;
  background: transparent;
  margin-bottom: 2rem;
  width: 100%;
}
