.GracefulError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &--dark {
    background-color: #181D26;
  }

  &--light {
    background-color: white;
  }

  &__heading {
    margin-bottom: 1.125rem;
  }

  &__main-text {
    white-space: pre-wrap;
  }

  &__icon {
    margin: 1.5rem 0;
    height: 5.5rem;
    width: 5.5rem;
    background-size: 5.5rem;
    display: inline-block;

    &--light {
      background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/error-icon-light2x.png')
    }

    &--dark {
      background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/error-icon-dark2x.png')
    }
  }


}
