.PageFooter {
  width: 100%;

  /*
    The order attributes here are to change the visual render order of these flex children,
    even when the tab/DOM order is different.

    The visual order should always be (from left to right): prev button, title, next button.
    The tab order can be changed by re-ordering these children in the actual HTML.
   */
  &__prev-button {
    order: 1
  }
  &__partition-title {
    order: 2;
  }
  &__next-button {
    order: 3;
  }
}
