.markdown-editor__container {
  position: relative;
  border: 1px solid black;
  border-radius: 5px;

  .ace_editor * {
    font-family: monospace;
    line-height: 1.5rem;
  }

  .markdown-editor__container__text-input {
    padding: 6px;
  }

  .ace_comment.ace_placeholder {
    font-style: normal;
    color: #697576;
  }

  .invalid__file__upload__alert__overlay {
    top: 0;
    z-index: 99;
  }
}
