@import 'src/theme/color-palette';
@import './lesson-editor';

.gadgetEditorContainer {
  box-sizing: border-box;
  cursor: default;
  min-height: 1em;
  position: relative;
  transition: border 0.28s;
  background: transparent;
  margin-bottom: 2rem;

  &:hover {
    .gadgetEditorContainer__control,
    .gadgetEditorContainer__control__checkbox {
      visibility: visible;
      &__hidden {
        visibility: hidden;
      }
    }
  }

  &__control {
    &__draggable {
      display: flex;
      justify-content: center;
      cursor: grab;
    }
  }

  &__control,
  &__control__checkbox {
    visibility: hidden;
  }

  &__control__checkbox {
    &.gadgetEditorContainer__control__checkbox__checked {
      visibility: visible;
    }
  }

  &__gadgetContainer {
    position: relative;

    &__selected {
      &__overlay {
        background-color: rgba(0, 0, 0, .8);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
      }
    }
  }

  .gadgetConfirmOverlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(248, 247, 241, .95);
    opacity: 1;
    z-index: 10;
  }
}
