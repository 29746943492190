@import '../../../../styles/common';
@import "@amzn/meridian-tokens/theme/blue-light";
@import "@amzn/meridian-tokens/component/input";

.GradedResponse {
  position: relative;

  &:focus-within::after {
    @include focus-ring;
  }

  &__text {
    // Learner Hub CSS is overriding this CSS. These important tags are a
    // temporary hack until we can develop a better long term solution.
    //
    // Do NOT replicate this pattern anywhere!
    border: 1px solid !important;
    box-sizing: border-box !important;

    height: $input-height-medium !important;
    border-radius: $input-border-radius !important;
    padding-left: $input-spacing-inset-horizontal !important;
    padding-right: calc(#{$input-spacing-inset-horizontal} + 2rem) !important;

    line-height: $input-line-height-default !important;
    font-size: $input-font-size-default !important;
    font-family: Amazon Ember, Arial, sans-serif !important;
    width: 100% !important;

    &:focus {
      outline: none;
    }
  }

  &--correct {
    background-color: #edfdf3 !important;
    border-color: #317567 !important;
  }

  &--incorrect {
    background-color: #fff3f0 !important;
    border-color: #bf2600 !important;
  }

  &__icon {
    position: absolute;
    right: $input-spacing-inset-horizontal;
    top: calc(50% - .5rem);

    &--correct {
      color: #317567;
    }

    &--incorrect {
      color: #bf2600;
    }
  }

  &--sr-only {
    @include sr-only;
  }
}
