@import '../../../../../theme/color-palette.scss';

.video-gadget__upload__placeholder {
  height: 200px;
  color: $gray300;;
  background-color: $gray025;
  border: 1px dashed $gray025;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  opacity: 0.9;
  font-family: "Amazon Ember";
}

.video-gadget__container {
  position: relative;
}

.transcript_error_container {
  height: 50px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: $red500;
  background-color: $red200;
  border: 1px dashed $gray025;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  opacity: 0.6;
  justify-content: center;
}

.upload_tick{
  color: $green500;
}

.placeholder_message{
  color: $gray700;
}

.alert-knockout-icon{
  color: $yellow300;
}

.info-knockout-icon{
  color: $red600;
  background-color: $red350;
  border-radius: 50%;
  transform: rotate(180deg);
}
