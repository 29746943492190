@use "/src/styles/common" as common;

.LabGadget {
  &__player {
    width: 100%;
    margin: 0 auto;

    iframe {
      border: 0;
      width: 100%;
      height: 80vh;
      display: block;
    }
  }
}
