@import 'src/theme/color-palette';
@import 'src/styles/common';

.lessonViewOptions__image {
  background: $gray025;
  cursor: pointer;

  &.active {
    background: $wave050;
  }
}
