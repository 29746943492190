@import 'src/styles/common';
@import '../../../../theme/color-palette.scss';

.AssessmentGadgetLayout {
  background-color: white;
}

.AssessmentGadgetLayout__header {
    padding-bottom: 1.625rem;
}

.AssessmentGadgetLayout__header-title {
  margin-bottom: 1rem;
}

.AssessmentGadgetLayout__footer {
    position: relative;
    left: -1.5rem;
    right: -1.5rem;
    border-top: 1px solid #eaeded;
    background-color: white;
    width: 100%;
    padding: 1.5rem 1.5rem 0;
}

.AssessmentGadgetLayout__review-answers-button-description {
  @include sr-only;
}

.AssessmentGrade {
    display: flex;
    align-items: center;
}

.AssessmentGrade__text {
    margin-right: 1.5rem;
}

.AssessmentGadgetQuestion__title {
    position: relative;
    background-color: $gray0;
    border-top: 1px solid #eaeded;
    width: 100%;
    top: 0;
    left: -1.5rem;
    right: -1.5rem;
    padding: 1.0rem 1.5rem 1.25rem 1.5rem;
}

.AssessmentGadgetQuestion {
    position: relative;
    border: none;
    padding: 0 0 1.25rem 0;
    margin: 0;
}

.AssessmentGadgetQuestion__title-main {
    display: flex;
    flex-direction: row;
}

.AssessmentGadgetQuestion__title::after {
    background-color: $gray0;
}

.AssessmentGadgetQuestion__title--passed,
.AssessmentGadgetQuestion__title--passed::after {
    background-color: #edfdf3;
    color: #317567;
}

.AssessmentGadgetQuestion__title--failed,
.AssessmentGadgetQuestion__title--failed::after {
    background-color: #fff3f0;
    color: #bf2600;
}

.AssessmentGadgetQuestion__title--passed::after,
.AssessmentGadgetQuestion__title--failed::after,
.AssessmentGadgetQuestion__title::after {
    width: 100%;
    height: 30px;
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
}

.AssessmentGadgetQuestion__title-main-text {
    flex-grow: 1;
    padding-right: 0.5rem;
}

.AssessmentGadgetQuestion__title-icon {
    margin: 0.25rem;
}

.AssessmentGadgetQuestion__help-text {
    margin-top: 0.5rem;
}

.AssessmentGadgetQuestion__explanation-text {
  white-space: pre-line;
}

.AssessmentGadgetQuestion__image {
    padding: .625rem 0;
    position: relative;
}
