.ResponseGadget {
  background-color: white;

  &__header {
    padding-bottom: 1.625rem;

    &-title {
      margin-bottom: 1rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    position: relative;
    left: -1.5rem;
    right: -1.5rem;
    border-top: 1px solid #eaeded;
    background-color: white;
    width: 100%;
    padding: 1.5rem 1.5rem 0;
  }

}
