@use 'src/styles/common' as common;

.LabPreview {
  & > * {
    font-family: "Amazon Ember", Arial, sans-serif;
  }

  &__header {
    background-color: common.$gray050;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    font-weight: bold;
    line-height: 1.5rem;
    color: common.$gray500;
    @include common.touch-gadget-container-side-edges;
    @include common.touch-gadget-container-top-edge;

    &--completed {
      color: common.$wave500;
    }

  }

  &__certificate {
    color: common.$gray500;
  }

  &__divider {
    border: 1px solid common.$gray200;
    margin: 1rem 0;
    color: common.$gray200;
  }

  &__challenge-list {
    margin: 0;
    padding: 0;

    &-item {
      list-style: none;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      &-number {
        font-size: 2.25rem;
        line-height: 3rem;
      }

      &-details {
        padding: 0.75rem 0;
      }
    }
  }

  iframe {
    border: 0;
    width: 70vh;
    height: 70vh;
    border-radius: 8px;
  }
}

.LabGadget__expandMode {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 9999;
  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}
