.LearnerQuestion {
  padding: 1.875rem 1.5rem 0;
  border-top: 1px solid #EAEDED;
  position: relative;
  left: -1.5rem;
  right: -1.5rem;
  width: 100%;

  &__question {
    padding-bottom: 1.5rem;
  }

  &__answer {
    padding-bottom: 1.875rem;
  }

  &__correct-answers {
    background-color: #f9fafa;
    position: relative;
    left: -1.5rem;
    right: -1.5rem;
    padding: 1.5rem;
    width: 100%;
  }

  &__correct-answers-list {
    padding-left: 0;
    margin: 0.375rem 0 0;
    list-style: none;
  }
}
