.gadget__content {
  box-sizing: border-box;
  background-color: white;
  position: relative;
  min-height: 1px;
  height: inherit;
  /* Just in case gadget content ever goes "out of bounds" */
  overflow: hidden;
  transition: height, min-height 0.28s;

  border: 1px solid #E7E9E9;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(11, 12, 12, 0.16);
  margin: 0 auto;
}
