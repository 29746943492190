@import 'src/theme/color-palette';
@import 'src/styles/common';

.sidebar-container {
  width: 350px;
  color: #FFFFFF;
  background-color: #141C2A;

  .sidebar-header {
    margin-bottom: 1rem;

    .overflow-menu-icon {
      transform: rotate(90deg);
    }
  }

  .lessons-container {
    overflow-y: auto;

    .lesson-list {
      list-style: none;
      padding: 0;
      margin: 0;

      &-item {
        margin-bottom: 0.375rem;
      }

      .overflow-menu-icon {
        transform: rotate(90deg);
      }
    }

    .lesson-container {
      position: relative;
      background: #232F3E;
      border-radius: 3px;
      padding: 0;
      border: 2px solid transparent;

      &.lesson-active {
        border: 2px solid $wave500;
      }

      &.lesson-drag-hover {
        border-top: 2px solid $wave500;
      }
    }
  }
}

.hide {
  display: none;
}
.show {
  display: block;
}

.lesson-title {
  background-color: #FFFFFF0D;

  .lesson-label-container {
    appearance: none;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
  }
}

.lesson-checkbox-label {
  @include sr-only;
}

.gadgets-container {
  font-size: 0.875rem;
  margin: 0;
  padding: 0.625rem 0 1rem 0;
  list-style: none;

  .gadget-list {
    list-style: none;
    padding: 0;
    margin: 0
  }

  .header-gadget-sidebar-container {
    &:not(:first-of-type) {
      margin-top: 1.5rem;
    }
  }
}

.gadget-container {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  cursor: pointer;
  line-height: 1.25rem;
  position: relative;
  word-wrap: break-word;
  white-space: break-spaces;
  justify-content: space-between;
  
  &--active {
    background-color: $wave500;

    &::before {
      content: ' ';
      background: white;
      position: absolute;
      left: 6px;
      height: 75%;
      width: 3px;
      border-radius: 4px;
    }
  }

  &:hover {
    background: #424E5C;
  }

  .gadget-icon {
    width: 16px;
    height: 16px;
  }
}
