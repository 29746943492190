@import 'src/theme/color-palette';
@import 'src/authoring/components/drag-and-drop/styles/dnd-utils';

.GadgetDropZone {
  visibility: hidden;

  &--hovering {
    @include dnd-list-item-hover-indicator;
    @include dnd-list-item-hover-indicator-over;

    &::before {
      visibility: visible;
    }
  }
}
