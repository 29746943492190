@use 'src/styles/common' as common;

.LabAuthoringPreview {
  & > * {
    font-family: "Amazon Ember", Arial, sans-serif;
  }

  &__header {
    background-color: common.$gray050;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    font-weight: bold;
    line-height: 1.5rem;
    color: common.$gray500;
    @include common.touch-gadget-container-side-edges;
    @include common.touch-gadget-container-top-edge;

    &--completed {
      color: common.$wave500;
    }

  }

  &__certificate {
    color: common.$gray500;
  }

  &__divider {
    border: 1px solid common.$gray200;
    margin: 1rem 0;
    color: common.$gray200;
  }

  &__challenge-list {
    margin: 0;
    padding: 0;

    &-item {
      list-style: none;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      &-number {
        font-size: 2.25rem;
        line-height: 3rem;
      }

      &-details {
        padding: 0.75rem 0;
      }
    }
  }

  &__build-in-progress-overlay {
    width: 100%;
    height: calc(100% - 40px);
    background-color: rgba(common.$gray100, 0.9);
    position: absolute;
    left: 0px;
    top: 40px;
    z-index: 10;
  }
}