@import 'src/theme/color-palette';

@mixin sr-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin focus-ring {
  content: '';
  position: absolute;
  border: 2px solid $wave500;
  border-radius: 0.375rem;
  top: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem;
  right: -0.25rem;
}

@mixin touch-gadget-container-side-edges {
  position: relative;
  width: 100%;
  left: -1.5rem;
  right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@mixin touch-gadget-container-top-edge {
  position: relative;
  top: -1.5rem;
}

@mixin touch-gadget-container-bottom-edge {
  position: relative;
  bottom: -1.5rem;
}
