
.AddableDnDList {
  &__drop-zone {
    height: 100%;
    width: 100%;

    &--invisible {
      display: none;
    }
  }
}
