@import 'src/theme/color-palette';

$dnd-list-item-line-height: 3px;
$dnd-list-item-hover-zone-padding: 1.875rem;
$dnd-list-item-hover-indicator-padding: calc(#{$dnd-list-item-hover-zone-padding} / 2 - #{$dnd-list-item-line-height});

@mixin dnd-list-item-hover-indicator {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: $dnd-list-item-line-height;
    background: $wave500;
  }
}

@mixin dnd-list-item-hover-indicator-over {
  padding-top: $dnd-list-item-hover-zone-padding;

  &::before {
    top: $dnd-list-item-hover-indicator-padding;
  }
}

@mixin dnd-list-item-hover-indicator-under {
  padding-bottom: $dnd-list-item-hover-zone-padding;

  &::before {
    bottom: $dnd-list-item-hover-indicator-padding;
  }
}
