.invalid__file__upload__alert__overlay {
  background-color: rgba(0, 0, 0, .8);
  height: calc(100% + 3rem);
  position: absolute;
  top: -1.5rem;
  width: calc(100% + 4.2rem);
  z-index: 2;
  margin-left: -2.1rem;
  overflow: auto;
}
