@import 'src/theme/color-palette';

.markdown-body {
  a, a:not([href]) {
    .link_text {
      text-decoration: underline;
    }

    .threat_detected_asset, .asset_not_processed, .asset_error {
      text-decoration: none;
    }

    .threat_detected_asset, .asset_error {
      color: $red400;
    }

    .asset_not_processed {
      color: $wave400;
    }
  }

  font-family: "Amazon Ember", Arial, sans-serif;

  h4 {
    font-size: 1.125rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
}
