@use 'src/styles/responsive.module' as responsiveStyles;

.video-gadget {
  .markdown-body {
    margin-bottom: 1rem;
  }

  .video-gadget-title {
    margin-bottom: 1rem;
  }
}

@media (max-width: responsiveStyles.$tablet-breakpoint) {
  .shaka-rewind-button,
  .shaka-fast-forward-button,
  .soju-theme .shaka-mute-button,
  .shaka-volume-bar-container {
    display: none;
  }
}
