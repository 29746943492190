.response-gadget__question__container {
  position: relative;
}

.response-gadget__question__delete-overlay {
  background-color: rgba(0, 0, 0, .8);
  height: calc(100% + 1rem);
  position: absolute;
  top: -0.5rem;
  width: calc(100% + 2.2rem);
  z-index: 2;
  margin-left: -1.1rem;
}
