@import '../../../../styles/common';

.quiz_gadget_answer {
  position: relative;
}

.quiz_gadget_answer_container__correct, .quiz_gadget_answer_container__correct:hover {
  background-color: #eaf6f5;
  border-color: #eaf6f5;
}

.quiz_gadget_answer_container__incorrect, .quiz_gadget_answer_container__incorrect:hover {
  background-color: #faf4f4;
  border-color: #faf4f4;
}

.quiz_gadget_answer_letter {
  position: relative;
  font-family: "Amazon Ember", Arial, sans-serif;
  color: black;
  background-color: white;
  border: 1px solid #BBC0C1;
  width: 1.375rem;
  height: 1.375rem;
  line-height: 1.375rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 0.75rem;
  margin-right: 0.5rem;
}

.quiz_gadget_answer_letter--singleselect {
  border-radius: 50%;
}

.quiz_gadget_answer_letter--multiselect {
  border-radius: 0.125rem;
}

.quiz_gadget_answer_letter--selected.quiz_gadget_answer_letter--passed {
  border: 1px solid #317567;
  background-color: #317567;
  color: white;
}

.quiz_gadget_answer_letter--selected.quiz_gadget_answer_letter--failed {
  border: 1px solid #bf2600;
  background-color: #bf2600;
  color: white;
}

.quiz_gadget_answer_letter--selected {
  color: white;
  background-color: #56595d;
  border: 1px solid #56595d;
}

.quiz_gadget_question_container {
  position: relative;
  padding-bottom: 20px;
}

.quiz_gadget_question_number {
  margin: 4px 5px 4px 0;
}

.quiz_gadget_answers_container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: none;
  background-color: white;
  left: -0.5rem;
  right: -0.5rem;
  width: 100%;
  padding: 8px;
}

.quiz_gadget_answer_container {
  font-size: 14px;
  border: 1px solid white;
  background-color: white;
  border-radius: 4px;
  margin: 8px 0;
  padding: 11px 9px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}

.quiz_gadget_answer_container--graded {
  border: 1px solid #e7e9e9;
}

.quiz_gadget_answer_container--passed,
.quiz_gadget_answer_container--failed {
  border: 1px solid #e7e9e9;
  background-color: white;
}

.quiz_gadget_answer_container--selected.quiz_gadget_answer_container--passed {
  border-color: #317567;
  background-color: #edfdf3;
}

.quiz_gadget_answer_container--selected.quiz_gadget_answer_container--failed {
  border-color: #bf2600;
  background-color: #fff3f0;
}

.quiz_gadget_answer_text {
  flex-grow: 1;
}

.quiz_gadget_answer_selected {
  margin-left: 0.5rem;
  margin-top: 0.25rem;
  display: flex;
  flex-direction: row;
}

.quiz_gadget_answer_selected_icon {
  margin-left: 0.5rem;
}

.quiz_gadget_answer_selected--passed {
  color: #317567;
}

.quiz_gadget_answer_selected--failed {
  color: #bf2600;
}

.quiz_gadget_radio:focus + label.quiz_gadget_answer_container,
.quiz_gadget_answer_container:not(.quiz_gadget_answer_container--graded):hover {
  cursor: pointer;
  border: 1px solid #eff0f1;
  background-color: #eff0f1;
}

.quiz_gadget_radio:focus + label.quiz_gadget_answer_container::after {
  @include focus-ring;
}

.quiz_gadget_answer_container:not(.quiz_gadget_answer_container--graded):hover {
  cursor: pointer;
}

.quiz_gadget_answer_container--selected {
  background-color: #eff0f1;
  border: 1px solid #90959d;
}

.quiz_gadget_sr_only {
  @include sr-only;
}

.quiz_gadget_radio {
  opacity: 0;
  position: absolute;
  top: 0.25rem;
  bottom: 0.25rem;
}

.quiz_gadget_text__correct {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: #21beb8;
}

.quiz_gadget_text__incorrect {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: #ed5b50;
}

.quiz_gadget_icon_success {
  font-size: 20px;
  color: #21beb8;
  margin-right: 10px;
}

.quiz_gadget_icon_fail {
  font-size: 20px;
  color: #ea5c54;
  margin-right: 10px;
}

.answer .radio__check {
  width: 14px;
  height: 14px;
}

.answer .radio {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.quiz_gadget_label__incorrect {
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.17;
  color: #ea5c54;
  display: inline-block;
  padding-bottom: 4px;
}

.quiz_gadget_label__correct {
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.17;
  color: #08beb9;
  display: inline-block;
  padding-bottom: 4px;
}

.quiz_gadget_result_container {
  background: #ffffff;
  padding: 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.quiz_gadget_result_column {
  width: 400px;
}

.quiz_gadget_result_row {
  font-size: 16px;
  margin-bottom: 30px;
}

.quiz_gadget_score_row {
  font-size: 14px;
  margin-bottom: 15px;
}

.quiz_gadget_score_row,
.quiz_gadget_result_row {
  display: flex;
  color: #534f43;
}

.quiz_gadget_score_row > span,
.quiz_gadget_result_row > span {
  width: 120px;
}

.quiz_gadget_score_row > span:nth-child(2),
.quiz_gadget_result_row > span:nth-child(2) {
  font-weight: bold;
}

.quiz_gadget_result_correct > span:nth-child(2) {
  color: #21beb8;
}

.quiz_gadget_result_incorrect > span:nth-child(2) {
  color: #ea5c54;
}

.quiz_gadget_submit_container {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: column;
  padding: 20px 0;
}

.quiz_gadget_submit_container button {
  max-width: 424px;
  height: 55px;
  font-size: 16px;
  width: 90%;
}

.quiz_gadget_submit_container__retake svg,
.quiz_gadget_submit_container__revert svg {
  margin-right: 10px;
  font-size: 20px;
}

.quiz_gadget_submit_container__retake {
  background: #ffffff;
}

.quiz_gadget_submit_container__retake:empty {
  display: none;
}

.quiz_gadget_submit_container__revert {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #2bbfca;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.quiz_gadget_submit_container__revert__loading > span {
  color: #7c7975;
  cursor: default;
  font-weight: normal;
}

@media (max-width: 767px) {
  .quiz_gadget_score_row>span:nth-child(2), .quiz_gadget_result_row>span:nth-child(2) {
    width: 100px;
  }
  .quiz_gadget_result_container>svg {
    display: none;
  }
}

.quiz_gadget_answer_explanation_container {
  margin-bottom: 1rem;
}

.quiz_gadget_answer_explanation_heading {
  margin-bottom: 1rem;
}

.quiz_gadget_answer_explanation_list {
  margin-left: 1rem;
}
