@use '/src/theme/color-palette.scss' as colors;

.labEditLayout {

  &__header {
    border-bottom: 1px solid colors.$gray300;
  }

  &__footer {
    border-top: 1px solid colors.$gray300;
  }
}
