@import '../../../../styles/common.scss';

.progressTrackerIndicator {
  &__container {
    &__completed {
      margin-bottom: 1rem;

      &__box {
        width: calc(100% + 2rem);
        margin-left: -1rem;

      }
    }
  }
  &__modal {
    &__completed {
      &__icon {
        /* The width and height need to match exactly the icon png dimensions */
        width: 82px;
        height: 81px;
        background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/checkmark-circle-icon-large.png');
        background-size: cover;
      }
    }
  }

  &__label {
    @include sr-only;
  }
}
