@import '../../../../theme/color-palette.scss';

$primary:		#FF0000;
$shaka-control-hover-background: $wave600;

.soju-theme {

  video {
    max-width: 100%;
    width: 100%;
  }

  * { font-family: "Amazon Ember", Arial, sans-serif; }
  // Moving bottom control to bottom
  .shaka-bottom-controls {
    width: 100%;
    padding: 0;
    z-index: 1;
  }

  .shaka-bottom-controls {
    display: flex; flex-direction: column;
  }

  .shaka-ad-controls {
    order: 1;
  }

  .shaka-controls-button-panel {
    order: 2;
    height: 40px;
    padding: 0 10px 10px;
  }

  .shaka-range-container {
    margin: 4px 10px 4px 10px; top: 0;
  }

  // Moving bottom control icons

  // Remove margin around buttons
  .shaka-controls-button-panel > * { margin: 0; padding: 3px 8px; color: #EEE; height: 32px;
    &:focus-visible { outline: none; box-shadow: inset 0 0 0 2px rgba(27, 127, 204, 80%); color: #FFF; }
    &:hover { color: #FFF;  }
  }

  // volume styles
  .shaka-controls-button-panel .shaka-volume-bar-container { position: relative; z-index: 10; left: -1px; opacity: 0; width: 50px; -webkit-transition: width 0.2s cubic-bezier(0.4,0,1,1); height: 3px;
    transition: width 0.2s cubic-bezier(0.4,0,1,1); padding: 0;
    &:hover, &:focus, &:focus-within {
      display: block; width: 50px; opacity: 1; padding: 0;
    }
  }
  .shaka-mute-button:hover + div { opacity: 1; width: 50px; padding: 0; }

  // shaka-current-time
  .shaka-current-time { padding: 0 10px; font-size: 12px; }

  // Range styles
  .shaka-seek-bar-container {
    height: 5px; position: relative; top: -1px; border-radius: 4px; margin-bottom: 0;
    .shaka-range-element { opacity: 1; }
    &:hover { height: 5px; top: -1px; cursor: pointer;
      .shaka-range-element { opacity: 1; cursor: pointer; }
    }
    input[type=range]::-webkit-slider-thumb {
      background: #ffffff;
      cursor: pointer;
    }

    input[type=range]::-moz-range-thumb {
      background: #ffffff;
      cursor: pointer;
    }

    input[type=range]::-ms-thumb {
      background: #ffffff;
      cursor: pointer;
    }
  }

  // TODO find a way to show volume range when focusing it.

  // Change icons

  .shaka-video-container {
    * { font-family: "Amazon Ember", Arial, sans-serif; }
    .material-icons-round {
      font-family: 'Material Icons Sharp';
    }
  }

  // Overflow menu styles

  // shaka-overflow-menu
  .shaka-overflow-menu, .shaka-settings-menu { border-radius: 2px; background: rgba(28,28,28,0.9); text-shadow: 0 0 2px rgb(0 0 0 / 50%); -webkit-transition: opacity .1s cubic-bezier(0,0,0.2,1); transition: opacity .1s cubic-bezier(0,0,0.2,1); -moz-user-select: none; -ms-user-select: none; -webkit-user-select: none;  right: 10px; bottom: 50px; padding: 8px 0; min-width: 200px;
  }
  .shaka-settings-menu { padding: 0 0 8px 0;
    button { font-size: 12px;
      span { margin-left: 33px; font-size: 13px; }
      &[aria-selected="true"]{
        display: flex;
        span { order: 2; margin-left:  0;  }
        i { order: 1; font-size: 18px; padding-left: 5px; }
      }
    }
  }

  .shaka-overflow-menu button { padding: 0;
    i { display: none; }
    .shaka-overflow-button-label { display: flex; justify-content: space-between; flex-direction: row; align-items: center; cursor: default; outline: none; height: 40px; flex: 0 0 100%;
      span { flex-shrink: initial; padding-left: 15px; font-size: 13px; font-weight: 500; display: flex; align-items: center; }
    }
  }

  // Smaller font-size for text below labels.
  .shaka-overflow-menu span + span {  color: #FFF; font-weight: 400 !important; font-size: 12px !important;  padding-right: 8px; padding-left: 0 !important;

  }

  .shaka-overflow-menu .shaka-pip-button {
    span + span { padding-right: 15px !important;
      &:after {
        content: "";
      }
    }
  }

  .shaka-back-to-overflow-button {
    padding: 8px 0; border-bottom: 1px solid rgba(255,255,255,0.2); font-size: 12px; color: #eee; height: 40px;
    .material-icons-round {
      font-size: 15px; padding-right: 10px;
    }
    span { margin-left: 3px !important; }
  }

  // Hover color
  .shaka-overflow-menu button:hover, .shaka-settings-menu button:hover {
    background-color: rgba(255,255,255,0.1); cursor: pointer;
    label { cursor: pointer; }
  }

  .shaka-overflow-menu button:focus, .shaka-settings-menu button:focus {
    background-color: rgba(255,255,255,0.1); border: none; outline: none;
  }

  // Text color
  .shaka-overflow-menu button, .shaka-settings-menu button {
    color: #EEE;
  }

  .shaka-controls-soju-seek {
    touch-action: manipulation;
  }

  .shaka-controls-soju-captions[aria-pressed='true'] {
    .icon--pressed {
      display: unset;
    }
    .icon--not-pressed {
      display: none;
    }
  }

  // shaka-overflow-menu-button button
  .shaka-overflow-menu-button {
    font-size: 18px;
  }

  .shaka-fullscreen-button {
    &:hover { font-size: 25px; -moz-transition: font-size .1s cubic-bezier(0.0,0.0,0.2,1);
      -webkit-transition: font-size .1s cubic-bezier(0.0,0.0,0.2,1);
      transition: font-size .1s cubic-bezier(0.0,0.0,0.2,1); }
  }

  @mixin shaka-button-style() {
    background-color: $shaka-control-hover-background;

    &[disabled='true'] {
      background-color: transparent;
      cursor: default;
    }
  }

  .shaka-overflow-menu-button,
  .shaka-fullscreen-button,
  .shaka-pip-button,
  .play-button,
  .pause-button,
  .shaka-mute-button,
  .shaka-controls-soju-seek,
  .shaka-controls-soju-captions {
    border-radius: 4px;

    &:hover, &:focus-visible {
      @include shaka-button-style();
    }
  }

  .custom-button-style {
    font-size: 0 !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px 18px;
    width: 32px;
  }

  .shaka-overflow-menu-button {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-settings.svg');
  }

  .enter-fullscreen {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-fullscreen-enter.svg');
  }

  .exit-fullscreen {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-fullscreen-exit.svg');
  }

  .play-button {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-play.svg');
  }

  .pause-button {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-pause.svg');
  }

  .mute-button {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-mute.svg');
  }

  .unmute-button {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-unmute.svg');
  }

  .enter-pip {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-pip-enter.svg');
  }

  .exit-pip {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-pip-exit.svg');
  }

  .rewind-button {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-rewind.svg');
  }

  .forward-button {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-fast-forward.svg');
  }

  .captions-enabled {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-captions-enabled.svg');
  }

  .captions-disabled {
    @extend .custom-button-style;
    background-image:url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-captions-disabled.svg');
  }

  .captions-not-available {
    @extend .custom-button-style;
    background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/soju-video-captions-not-available.svg');
  }

  .shaka-controls-container {
    button:focus-visible {
      outline: none;
    }
  }

  .shaka-range-element {
    height: 12px !important;
  }

}
