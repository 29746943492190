@import 'src/theme/color-palette';

/* Course nav... */
.courseNav {
  /* ...fills vertical space */
  height: 100%;

  /* ...manages the buttons flexible box... */
  display: flex;

  /* ...with items in a row */
  flex-direction: row;

  /* ...spreads them out */
  justify-content: space-between;

  &__page-footer {
    width: 100%;
    background-color: $gray0;
    border-top: 1px solid $gray200;
  }

  &__last-page-footer {
    &-divider {
      margin: 0 3rem;
      border-color: $gray0;
      border-width: 1px;
    }
  }
}

/* Button... */
.courseNav__button {
  /* ...each button is also a flexible box */
  display: flex;

  /* ...aligns the label vertically */
  align-items: center;

  /* ...aligns the label horizontally */
  justify-content: flex-start;
}

.courseNav__button:last-child {
  justify-content: flex-end;
}

.courseNav__button:last-child .courseNav__content {
  flex-direction: row-reverse;
}

.courseNav__button_gadgets {
  min-width: 390px;
}

.courseNav__button_sidebar {
  display: none;
}

.courseNav__container {
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: var(--desktopScreenWidth)) {
  .courseNav__button_gadgets {
    display: none;
  }

  .courseNav__button_sidebar {
    display: flex;
  }
}

.courseNav__content {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.courseNav__label {
  max-width: 127px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 10px;
}

@media (max-width: var(--extraSmallViewportWidth)) {
  .courseNav__label {
    max-width: 60px;
  }
}
