.reading-gadget-container {
  background-color: #FFFFFF;
}

.reading-gadget-title {
  margin-bottom: 1rem;
}

.ql-container {
  font-family: "Amazon Ember", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;

  &.ql-snow {
    border: 0;
  }

  .ql-editor {
    padding: 0;
    line-height: 1.5rem;
  }

  .ql-editor {
    h4 {
      font-size: 1.125rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }

  .ql-editor ol,
  .ql-editor ul {
    margin-bottom: 0.5rem;
  }
}
