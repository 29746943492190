@use 'src/styles/responsive.module' as responsiveStyles;

.globalNavbar {
  &__courseMenu {
    &__button {
      &__icon {
        height: 20px;
        width: 20px;
        background-image: url('https://m.media-amazon.com/images/G/01/courses/soju-player/icons/vertical-line-left-arrow.png');
        background-size: 100% auto;
        background-repeat: none;
      }
    }
  }
}

@media (max-width: responsiveStyles.$tablet-breakpoint) {
  .globalNavbar__wrapper {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
  }

  .globalNavbar__title__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
