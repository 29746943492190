// Blue scale
$blue025: #F4F9FB;
$blue050: #DDEFF3;
$blue100: #A8DFEE;
$blue200: #68C1DE;
$blue300: #1099CB;
$blue400: #0075A3;
$blue500: #005276;
$blue600: #1C374A;
$blue700: #232F3E;
$blue800: #141C2A;

// Green wave scale (Brand)
$wave025: #F4FBF8;
$wave050: #DDF3EB;
$wave100: #A6E7CE;
$wave200: #68DEBF;
$wave300: #10CBA9;
$wave400: #00A38D;
$wave500: #008577;
$wave600: #005F61;
$wave700: #00464F;
$wave800: #05282E;

// Gray scale
$gray0: #FFFFFF;
$gray025: #F7F7F8;
$gray050: #EFF0F1;
$gray100: #D0D3D7;
$gray200: #B5BAC0;
$gray300: #99A0A8;
$gray400: #7C8692;
$gray500: #626C7A;
$gray600: #424E5C;
$gray700: #232F3E;
$gray800: #181D26;

// Red scale
$red100: #FFF3F0;
$red200: #FFBDAD;
$red250: #F3B9BA;
$red300: #FF5630;
$red350: #FF7494;
$red400: #DE350B;
$red500: #BF2600;
$red600: #AD0A30;

// Tangerine scale
$tangerine100: #FFE5CB;
$tangerine200: #FFCB96;
$tangerine300: #FFAE5D;
$tangerine400: #FF8F00;
$tangerine500: #D4780C;
$tangerine600: #A45C0A;

// Yellow scale
$yellow100:	#FEF2B8;
$yellow300: #FCDE4D;
$yellow400:	#FAD000;
$yellow500:	#DCB700;

// Green scale
$green100: #DCF3A0;
$green300: #C3EB5E;
$green400: #ADE422;
$green500: #98C91E;

:export {
  blue025: $blue025;
  blue050: $blue050;
  blue100: $blue100;
  blue200: $blue200;
  blue300: $blue300;
  blue400: $blue400;
  blue500: $blue500;
  blue600: $blue600;
  blue700: $blue700;
  blue800: $blue800;
  wave025: $wave025;
  wave050: $wave050;
  wave100: $wave100;
  wave200: $wave200;
  wave300: $wave300;
  wave400: $wave400;
  wave500: $wave500;
  wave600: $wave600;
  wave700: $wave700;
  wave800: $wave800;
  gray0: $gray0;
  gray025: $gray025;
  gray050: $gray050;
  gray100: $gray100;
  gray200: $gray200;
  gray300: $gray300;
  gray400: $gray400;
  gray500: $gray500;
  gray600: $gray600;
  gray700: $gray700;
  gray800: $gray800;
  red100: $red100;
  red200: $red200;
  red250: $red250;
  red300: $red300;
  red400: $red400;
  red500: $red500;
  red600: $red600;
  tangerine100: $tangerine100;
  tangerine200: $tangerine200;
  tangerine300: $tangerine300;
  tangerine400: $tangerine400;
  tangerine500: $tangerine500;
  tangerine600: $tangerine600;
  green100: $green100;
  green300: $green300;
  green400: $green400;
  green500: $green500;
  yellow100: $yellow100;
  yellow300: $yellow300;
  yellow400: $yellow400;
  yellow500: $yellow500;
}
