@use '/src/theme/color-palette.scss' as colors;

.trackChallenge {
  &__card {
    border: 1px solid colors.$gray200;
    box-shadow: 0 2px 4px rgba(colors.$gray800, .29);

    &__script {
      &--completed {
        color: colors.$wave500;
      }
    }
  }
}
