.transcript_error_container {
  height: 50px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #8b0000;
  background-color: #f08080;
  border: 1px dashed #f8f7f1;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  opacity: 0.6;
  justify-content: center;
}

.transcript_container {
  border-top: 1px solid #D7D7D7;
}

.transcript_container-wrapper {
  padding-top: 15px;
}

.transcript_interactive {
  padding-top: 10px;
  color: #232F3E;
  font-family: "Amazon Ember", Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.transcript_type_text {
  white-space: pre-wrap;
}

.transcript-phrase.active {
  background-color: #eed6a9;
}
