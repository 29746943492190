@use '/src/theme/color-palette.scss' as colors;

.altTextAction {
  &__container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -30px;
    width: 100%;

    &__innerBox {
      padding: 0.5rem;
      background: colors.$gray0;
      border: 1px solid colors.$gray800;
      border-radius: 4px;
    }
  }
}
