.slideshow-gadget-title {
  margin-bottom: 1rem;
}

.carousel {
  width: 100%;
}

.carousel__viewport {
  width: 100%;
  overflow: hidden;
}

.carousel__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.carousel__viewport.is-dragging {
  cursor: grabbing;
}

.carousel__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.carousel__slide {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 250px;
  margin-right: 10px;
}

.carousel__slide__image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.no-image-loaded-yet .ql-editor {
  max-height: 590px;
}

.with-image .ql-editor {
  max-height: 350px;
}

.no-image-slideshow .ql-editor {
  max-height: 800px;
}

.carousel__nav {
  display: flex;
  justify-content: center;
  position: relative;
  border-top: 1px solid #eaeded;
  left: -1.5rem;
  right: -1.5rem;
  width: 100%;
  padding: 1.5rem 1.5rem 0;
}

.slideshow-gadget__container {
  position: relative;
}
