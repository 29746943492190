@use '/src/theme/color-palette.scss' as colors;

.labChallengeScripts {
  &__statusIcon {
    margin-right: 0.5rem;

    &--completed {
      color: colors.$wave500;
    }
  }
}