@import 'src/styles/common';

.SojuImage {
  &__loader {
    height: 200px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }

  &__link {
    cursor: pointer;
    position: relative;
    display: inline-block;
    appearance: none;
    border: none;
    padding: 0;
    background: none;
    margin: 0.25rem;

    &:focus-visible {
      outline: none;

      &::after {
        @include focus-ring;
      }
    }
  }

  &__image {
    &.is-loading {
      display: none;
    }

    &.fill {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    @include sr-only;
  }
}
