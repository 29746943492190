@use '/src/theme/color-palette.scss' as colors;
@mixin code-block-line-numbers {
  padding-left: 2.5rem;
  position: relative;

  &::before {
    $content: '';
    // This will generate numbering for up to 99 lines
    @for $i from 1 to 99 {
        $content: $content + $i + '\a';
    }
    content: $content;
    white-space: pre;
    position: absolute;
    left: 0.5rem;
    height: calc(100% - 10px); // -10px to account for top padding added by quill
    overflow-y: hidden;
    border-right: 1px colors.$gray300 solid;
    padding-right: 0.25rem;
    font-size: 1rem;
    color: colors.$gray300;
  }
}

.ql-edit-mode {
  .ql-toolbar {
    &.ql-snow {
      border: 0;

      + .ql-container {
        &.ql-snow {
          border: solid 1px black;
        }
      }
      button {
        .ql-active,
        &:hover {
          fill: #06c;
        }
      }
  
      .ql-picker {
        &.ql-header {
          .ql-picker-label,
          .ql-picker-item {
            &::before {
              content: 'Paragraph';
            }
          }
  
          .ql-picker-label[data-value="4"],
          .ql-picker-item[data-value="4"] {
            &::before {
              content: 'Subheader';
            }
          }
        }
      }
    }
  }
  .ql-container {
    font-family: "Amazon Ember", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;

    &.ql-snow {
      margin-top: 10px;
      border-radius: 5px;
      border: solid 1px black;
      padding: 10px;
    }

    .ql-editor {
      padding: 0;
      line-height: 1.5rem;
      min-height: 150px;
    }

    .ql-editor {
      h4 {
        font-size: 1.125rem;
        margin-bottom: 1rem;
        font-weight: bold;
      }
    }

    .ql-editor ol,
    .ql-editor ul {
      margin-bottom: 0.5rem;
    }
  }
}
